import { useContext } from "react";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";

export const AccessibilitySection = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();

    return (
        <section className="mb-[100px] mt-[100px] flex justify-center" id="onas">
            <div className="gap-20 pt-[30px]">
                <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold font-['Roboto'] leading-[47.41px] text-center md:pb-[58px] pb-[10px]"}>
                    {t("O nas")}
                </h2>
                <div className="flex md:flex-row flex-col">
                    <div className=" md:text-left text-center">
                        <h2 className="font-medium font-['Roboto'] text-custom-32px md:leading-10 leading-[22px]">
                            <span className={(isContrast ? "text-yellow-400" : "text-black")}>
                                {t("Zespół PIRS Creative Lab to grupa ludzi")}
                            </span>
                            <span className="text-cyan-700">
                                {" " + t("znających i rozumiejących")}
                            </span>
                            <span className={(isContrast ? "text-yellow-400" : "text-black")}>
                                {" " + t("codzienne wyzwania i potrzeby osób z niepełnosprawnością wzroku.")}
                            </span>
                        </h2>
                        <div className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-[601px] text-left text-custom-19px font-normal font-['Roboto']  md:leading-8 leading-5 my-10"} >
                            <span> {t("Właśnie dlatego powstał TOTUPOINT – system znaczników udźwiękowiających przestrzeń, którego pomysłodawcą i twórcą jest Jan Szuster – niewidomy inżynier i informatyk. Nasze znaczniki nie tylko pomagają osobom z dysfunkcją wzroku trafiać samodzielnie do budynków, ale również ułatwiają poruszanie się wewnątrz nich.")}</span>
                        </div>
                    </div>
                    <div className="md:my-auto mx-auto">
                        <img
                            className="object-cover md:w-full w-60"
                            alt="Pirs logo"
                            src="https://c.animaapp.com/KH8BuEma/img/pirs---logo--3--1@2x.png"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}