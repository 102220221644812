import { useEffect } from "react";
import { Link } from "react-router-dom";

export const MobileAppRedirect = () => {

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor;
        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=pl.pirslab.totupoint&hl=en&pli=1';
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.href = 'https://apps.apple.com/pl/app/totupoint/id917319251?l=pl';
        }

    }, []);

    return <div className="my-7 w-full  text-center ">
        <Link className="text-blue-400" to={"/"}>Wróć do strony głównej</Link>
    </div>
};
