import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ContentPage.css';
import { AccessibilityContext } from '../../contexts/AccessibilityContext';
import parse from 'html-react-parser';


export const ContentPage = () => {
    const { name } = useParams();
    const [content, setContent] = useState({ title: '', content: '', date: '' });
    const imagesMap: any = {
        "1": require("../../assets/miniatury/1.jpg"),
        "2": require("../../assets/miniatury/2.jpg"),
        "3": require("../../assets/miniatury/3.jpg"),
        "4": require("../../assets/miniatury/4.jpg"),
        "5": require("../../assets/miniatury/5.jpg"),
        "6": require("../../assets/miniatury/6.jpg"),
    };
    const headerRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.focus();
        }
    }, [content]);

    useEffect(() => {
        fetch(process.env.REACT_APP_SERVER_BE_IP + `getwebcontent.php?name=${name}`)
            .then(response => response.json())
            .then(data => {
                const modifiedContentfirst = data.content.replace(/strona\.php\?nazwa=([^"]+)/g, '$1');
                const modifiedContentSecond = modifiedContentfirst.replace(/(href=")dokumenty\/formularz_lokalizacji\.xlsx"/g, 'href=\"https://view.officeapps.live.com/op/view.aspx?src=http%3A%2F%2Fwww.totupoint.pl%2Fdokumenty%2Fformularz_lokalizacji.xlsx&wdOrigin=BROWSELINKhttps://view.officeapps.live.com/op/view.aspx?src=http%3A%2F%2Fwww.totupoint.pl%2Fdokumenty%2Fformularz_lokalizacji.xlsx&wdOrigin=BROWSELINK\"');

                const modifiedContent = parse(modifiedContentSecond, {
                    replace: (domNode: any) => {
                        if (domNode.name === 'img' && domNode.attribs && domNode.attribs.src) {
                            const match = domNode.attribs.src.match(/obrazy\/miniatury\/(\d+)\.jpg/);
                            if (match && imagesMap[match[1]]) {
                                return React.createElement('img', {
                                    src: imagesMap[match[1]],
                                    alt: domNode.attribs.alt || '',
                                    style: { width: '100%' }
                                });
                            }
                        }
                    }
                });

                const newContent = { ...data, content: modifiedContent };
                setContent(newContent);
            })
            .catch(error => console.log(error));
    }, [name]);

    const { isContrast } = useContext(AccessibilityContext);
    return (
        <section className={(isContrast ? "text-yellow-400" : "text-black") + " mt-[50px]"}>
            <div ref={headerRef} tabIndex={-1} className='text-center header'>
                <h1 className='text-custom-xl'>{content.title}</h1>
            </div>
            <div className='contentParagraf'>
                {content.content} {/* Tutaj zmieniono na dynamiczne renderowanie */}
            </div>
            <div className='flex flex-row mt-[20px]'>
                <span className='text-custom-sm font-normal ml-auto'>Aktualizacja: {content.date}</span>
            </div>
        </section>
    );
}