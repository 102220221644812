import React, { ReactNode, useEffect, useState } from 'react';
import { LocationDetail } from '../models/Location';
import axios from 'axios';
import { size } from 'lodash';

interface LinkData {
    title: string;
    name: string;
}


interface IAccessibilityContext {
    sizeText: number;
    changeSizeText: (value: number) => void;
    isContrast: boolean;
    setIsContrast: (value: boolean) => void;
    activeLink: string;
    setActiveLink: (value: string) => void;
    links: LinkData[];
}

export const AccessibilityContext = React.createContext<IAccessibilityContext>({
    sizeText: 0,
    changeSizeText: (value: number) => { },
    isContrast: false,
    setIsContrast: (value: boolean) => { },
    activeLink: "",
    setActiveLink: (value: string) => { },
    links: []
});

interface IAccessibilityProvider {
    children: ReactNode;
}

export const AccessibilityProvider: React.FC<IAccessibilityProvider> = ({ children }) => {
    const [sizeText, setSizeText] = useState<number>(0);
    const [isContrast, setIsContrast] = useState<boolean>(false);
    const [activeLink, setActiveLink] = useState<string>("");

    const [links, setLinks] = useState<LinkData[]>([]);
    useEffect(() => {
        axios.get('/api/getwebcontent.php?name=sidebar')
            .then(response => {
                const data: LinkData[] = response.data;
                setLinks(data);
            })
            .catch(error => console.error('Error fetching links:', error));
    }, []);

    const changeSizeText = (value: number) => {
        setSizeText(value)
    }
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth <= 767) {
                // Ustawienia dla urządzeń mobilnych
                changeFontSize('--font-size-xl', 17);
                changeFontSize('--font-size-4xl', 25);
                changeFontSize('--font-size-8xl', 45);
                changeFontSize('--font-size-custom-12px', 12);
                changeFontSize('--font-size-custom-14px', 12); //
                changeFontSize('--font-size-custom-16px', 14);
                changeFontSize('--font-size-custom-17px', 15);
                changeFontSize('--font-size-custom-19px', 13);
                changeFontSize('--font-size-custom-28px', 21); //
                changeFontSize('--font-size-custom-30px', 20);
                changeFontSize('--font-size-custom-32px', 20);
                changeFontSize('--font-size-custom-40px', 30); //
                changeFontSize('--font-size-custom-extended-40px', 25); //
                changeFontSize('--font-size-custom-extended-16px', 16); //
            } else {
                // Ustawienia dla ekranów większych (desktop)
                changeFontSize('--font-size-xl', 16);
                changeFontSize('--font-size-4xl', 32);
                changeFontSize('--font-size-8xl', 70)
                changeFontSize('--font-size-custom-12px', 15);
                changeFontSize('--font-size-custom-14px', 14);
                changeFontSize('--font-size-custom-16px', 16);
                changeFontSize('--font-size-custom-17px', 17);
                changeFontSize('--font-size-custom-19px', 15);
                changeFontSize('--font-size-custom-28px', 20);
                changeFontSize('--font-size-custom-30px', 26);
                changeFontSize('--font-size-custom-32px', 28);
                changeFontSize('--font-size-custom-40px', 38); //
                changeFontSize('--font-size-custom-extended-16px', 18); //
                changeFontSize('--font-size-custom-extended-40px', 36);

                // changeFontSize('--font-size-xl', 20);
                // changeFontSize('--font-size-4xl', 36);
                // changeFontSize('--font-size-8xl', 96)
                // changeFontSize('--font-size-custom-12px', 20);
                // changeFontSize('--font-size-custom-14px', 14);
                // changeFontSize('--font-size-custom-16px', 16);
                // changeFontSize('--font-size-custom-17px', 17);
                // changeFontSize('--font-size-custom-19px', 19);
                // changeFontSize('--font-size-custom-28px', 28);
                // changeFontSize('--font-size-custom-30px', 30);
                // changeFontSize('--font-size-custom-32px', 32);
                // changeFontSize('--font-size-custom-40px', 47); //
                // changeFontSize('--font-size-custom-extended-16px', 26); //
                // changeFontSize('--font-size-custom-extended-40px', 40);
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [sizeText]);

    const changeFontSize = (nameArgFontSize: string, baseFontSize: number) => {
        if (nameArgFontSize == "--font-size-custom-extended-16px") {
            document.documentElement.style.setProperty(nameArgFontSize, `${baseFontSize}px`);
        } else {
            const newSize = baseFontSize + sizeText;
            document.documentElement.style.setProperty(nameArgFontSize, `${newSize}px`);
        }

    }


    return (
        <AccessibilityContext.Provider value={{ sizeText, isContrast, changeSizeText, setIsContrast, activeLink, setActiveLink, links }}>
            {children}
        </AccessibilityContext.Provider>
    );
};
