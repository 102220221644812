import React from 'react';
import { IStatsRow } from "./StatsContener";
import { useTranslation } from 'react-i18next';

interface IStatsRowComponent {
    stats: IStatsRow;
    isSelected: boolean;
    onClick: () => void;
    tabIndex: number;
}

export const StatsRow: React.FC<IStatsRowComponent> = ({ stats, isSelected, onClick, tabIndex }) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onClick();
        }
    };

    const { t } = useTranslation();

    return (
        <div className='' onKeyDown={handleKeyDown}>
            <div
                className={(isSelected ? "border-2 border-gray-400 " : "") + "md:px-2 md:pt-5 py-2 px-1 bg-white rounded-xl shadow flex-col justify-between items-center gap-[7px] inline-flex md:h-[200px] md:w-[230px] h-[160px]"}
                style={{ boxShadow: "0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)" }}
                aria-label={t('Rozwiń szczegóły lokalizacji')}
            >
                <div className="flex flex-col">
                    <span className="text-center text-black text-custom-extended-40px font-semibold">{stats.length}</span>
                    <span className="md:w-full mx-1 w-[130px] text-center text-black text-custom-extended-16px font-light">{t(stats.title)}</span>
                </div>
                {!isSelected ?
                    <button
                        type="button"
                        tabIndex={0}
                        onClick={onClick}
                        aria-label={t('Rozwiń szczegóły lokalizacji') + ': ' + stats.title}
                        className={(isSelected ? "bg-cyan-700" : "bg-cyan-700") + " border border-gray-400 focus:border-black focus:border-2 text-white rounded-md md:px-4 md:py-1 px-1 py-0.5 transition duration-500 ease select-none hover:bg-gray-800 focus:outline-none focus:shadow-outline text-custom-12px"}
                    >
                        {t('Rozwiń')}
                    </button>
                    :
                    <button
                        type="button"
                        tabIndex={0}
                        disabled
                        aria-label={t('Rozwinięto szczegóły lokalizacji') + ': ' + stats.title}
                        className={"bg-slate-900 border border-gray-400 text-white rounded-md md:px-4 md:py-1 px-1 py-0.5 transition duration-500 ease select-none text-custom-12px"}
                    >{t('Rozwinięto')}</button>
                }
            </div>
        </div>
    );
}
