import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AccessibilityContext } from '../contexts/AccessibilityContext';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

interface SidebarProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}
interface LinkData {
    title: string;
    name: string;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, setIsOpen }) => {
    const { activeLink, setActiveLink, links } = useContext(AccessibilityContext);
    const firstFocusableElementRef = useRef<any>(null);
    const lastFocusableElementRef = useRef<any>(null);



    useEffect(() => {
        if (isOpen) {
            firstFocusableElementRef.current?.focus();
        }
    }, [isOpen]);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setIsOpen(false);
        }

        if (event.key === 'Tab') {
            if (event.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableElementRef.current) {
                    lastFocusableElementRef.current?.focus();
                    event.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableElementRef.current) {
                    firstFocusableElementRef.current?.focus();
                    event.preventDefault();
                }
            }
        }
    };
    const handleLinkClick = (path: string, titleWeb: string) => {
        document.title = titleWeb + " - TOTUPOINT";
        setActiveLink(path);
        setIsOpen(false);
    };
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <aside>
            <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={() => setIsOpen(false)}></div>
            <div className="fixed top-0 right-0 bg-white md:h-[85%] h-[100%] md:w-[500px] z-20">
                <div tabIndex={1} ref={firstFocusableElementRef} className="sr-only">Początek sidebaru</div>
                <div className="md:mr-[200px] mr-[30px] mt-[40px] text-right">
                    <button
                        className='cursor-pointer'
                        tabIndex={1}
                        onKeyDown={handleKeyDown}
                        onClick={() => setIsOpen(false)}
                        aria-label={t('Zamknij menu boczne')}
                    >
                        X
                    </button>
                </div>
                <div className="ml-[50px] flex flex-col gap-3 mt-10 font-bold">
                    {links.map(link => (
                        <Link
                            key={link.name}
                            tabIndex={1}
                            to={`/${link.name}`}
                            onClick={() => handleLinkClick(link.name, link.title)}
                            className={`text-black text-custom-xl cursor-pointer ${activeLink === link.name ? 'text-blue-500' : ''}`}
                            aria-current={activeLink === link.name ? 'page' : undefined}
                        >
                            {t(link.title)}
                        </Link>
                    ))}
                </div>
                <div tabIndex={1} ref={lastFocusableElementRef} className="sr-only">Koniec sidebaru</div>
            </div>
        </aside>
    );
};

export default Sidebar;