import { useContext } from "react";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import menwithmarker from "../../../assets/icons/menwithmarker.png"
import { useTranslation } from "react-i18next";

export const HowItWorksSection = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();

    return (
        <section className="diamond-template bg-[#F9B70D]/10 py-[100px] flex justify-center" id="onas">
            <div className="-rotate-[4.12deg] md:w-[900px] md:max-w-max max-w-md gap-20 pt-[30px] flex flex-col justify-center items-center">
                <div className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold leading-[47.41px]"}>
                    <h2>{t("Jak działa nasz system?")}</h2>
                </div>
                <div className="flex md:flex-row flex-col md:mt-20 gap-2 md:ml-30 mx-12 md:mb-[50px] mb-[40px]">
                    <div className="flex flex-col gap-20">
                        <div className="flex flex-row md:gap-14 gap-4">
                            <span className="text-[80px] text-cyan-700 font-bold" aria-label={t("Punkt") + "1"}>1</span>
                            <div className="flex flex-col">
                                <h3 className="text-cyan-700 text-custom-32px font-semibold leading-[37.93px]">
                                    {t("Montaż urządzeń")}
                                </h3>
                                <p className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-[441px] max-w-md text-custom-19px font-normal leading-loose"}>
                                    {t("Znaczniki TOTUPOINT to niewielkie bezprzewodowe urządzenia, które montujemy nad wejściami do budynków oraz w ich strategicznych miejscach.")}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row md:gap-14 gap-4">
                            <span className="text-[80px] text-cyan-700 font-bold" aria-label={t("Punkt") + "2"}>2</span>
                            <div className="flex flex-col">
                                <h3 className="text-cyan-700 text-custom-32px font-semibold leading-[37.93px]">
                                    {t("Aktywacja znacznika")}
                                </h3>
                                <p className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-[441px] max-w-md text-custom-19px font-normal leading-loose"}>
                                    {t("W momencie, w którym użytkownik systemu włączy naszą bezpłatną aplikację “TOTUPOINT” oraz Bluetooth w telefonie komórkowym i zbliży się w pole działania znacznika, ten odezwie się komunikatem dźwiękowym.")}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row md:gap-14 gap-4">
                            <span className="text-[80px] text-cyan-700 font-bold" aria-label={t("Punkt") + "3"}>3</span>
                            <div className="flex flex-col">
                                <h3 className="text-cyan-700 text-custom-32px font-semibold leading-[37.93px]">
                                    {t("Precyzyjne instrukcje")}
                                </h3>
                                <p className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-[441px] max-w-md text-custom-19px font-normal leading-loose"}>
                                    {t("Komunikat ten pomaga użytkownikowi zlokalizować słuchem kierunek oraz poznać nazwę miejsca, w którym TOTUPOINT został zamontowany. Dodatkowo w momencie wykrycia w otoczeniu znacznika, aplikacja wyświetla oraz czyta z telefonu opis przestrzeni wokół niego, uwzględniając wszelkie bariery architektoniczne.")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="md:mt-auto mt-10 md:mb-auto relative left-20">
                        <img className="md:w-[450px] w-[200px] pt-[10px]" src={menwithmarker} alt={t("Niewidomy mężczyzna używający aplikacji TOTUPOINT")} />
                    </div>
                </div>
            </div>
        </section>
    );
}