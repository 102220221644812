import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import photo1 from '../../../assets/photoSlider/1.jpg';
import photo2 from '../../../assets/photoSlider/2.jpg';
import photo3 from '../../../assets/photoSlider/3.jpg';
import photo4 from '../../../assets/photoSlider/4.jpg';
import photo5 from '../../../assets/photoSlider/5.jpg';
import photo6 from '../../../assets/photoSlider/6.jpg';
import photo7 from '../../../assets/photoSlider/7.jpg';
import photo8 from '../../../assets/photoSlider/8.jpg';
import photo9 from '../../../assets/photoSlider/9.jpg';
import photo10 from '../../../assets/photoSlider/10.jpg';
import photo11 from '../../../assets/photoSlider/11.jpg';
import photo12 from '../../../assets/photoSlider/12.jpg';
import photo13 from '../../../assets/photoSlider/13.jpg';
import photo14 from '../../../assets/photoSlider/14.jpg';
import photo15 from '../../../assets/photoSlider/15.jpg';
import photo16 from '../../../assets/photoSlider/16.jpg';
import photo17 from '../../../assets/photoSlider/17.jpg';
import photo18 from '../../../assets/photoSlider/18.jpg';
import photo19 from '../../../assets/photoSlider/19.jpg';
import photo20 from '../../../assets/photoSlider/20.jpg';
import photo21 from '../../../assets/photoSlider/21.jpg';
import photo22 from '../../../assets/photoSlider/22.jpg';
import photo23 from '../../../assets/photoSlider/23.jpg';
import leftArrow from "../../../assets/icons/left-arrow.png"


import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import { nextTick } from "process";
import { useTranslation } from "react-i18next";

export const GallerySection = () => {
    const [currentStartIndex, setCurrentStartIndex] = useState(0);
    const photos = [photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9, photo10, photo11, photo12, photo13, photo14, photo15, photo16, photo17, photo18, photo19, photo20, photo21, photo22, photo23];
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
    const { isContrast } = useContext(AccessibilityContext);

    useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            setCurrentStartIndex((currentStartIndex) => (currentStartIndex + 1) % photos.length);
        }, 5000);

        return () => {
            if (intervalIdRef.current) clearInterval(intervalIdRef.current);
        }; // Czyści interwał, gdy komponent jest demontowany
    }, [currentStartIndex]);

    const resetTimer = () => {
        if (intervalIdRef.current) clearInterval(intervalIdRef.current);
        intervalIdRef.current = setInterval(() => {
            setCurrentStartIndex((currentStartIndex) => (currentStartIndex + 1) % photos.length);
        }, 5000);
    };

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentStartIndex(parseInt(event.target.value));
        resetTimer();
    };

    const visiblePhotos = [
        photos[currentStartIndex % photos.length],
        photos[(currentStartIndex + 1) % photos.length],
        photos[(currentStartIndex + 2) % photos.length],
        photos[(currentStartIndex + 3) % photos.length],
        photos[(currentStartIndex + 4) % photos.length],
    ];
    const handleNext = () => {
        setCurrentStartIndex((currentStartIndex) => (currentStartIndex + 1) % photos.length);
        resetTimer();
    };

    const handlePrev = () => {
        setCurrentStartIndex((currentStartIndex) => (currentStartIndex - 1 + photos.length) % photos.length);
        resetTimer();
    };
    const { t } = useTranslation();

    return (
        <section className="flex flex-col justify-center items-center mb-[40px] animate-wiggle md:pt-[100px] pt-[50px]" id="galeria">
            <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold leading-[47.41px] mb-[88px]"}>
                {t("Galeria")}
            </h2>
            <div className="flex justify-center items-center relative photos">
                <img onClick={handlePrev} className="p-10 z-10 ml-10 absolute h-32 md:right-[570px] right-[200px]" alt={t("Strzałka do poprzedniego zdjęcia")} src={leftArrow} />
                <img
                    key={Date.now() + 1}
                    src={visiblePhotos[0]}
                    className="w-[225px] h-[300px] transition-opacity duration-500 ease-in-out rounded-md absolute -left-[300px] shadow-2xl photo object-contain"
                    alt={t("Pozycja zdjęcia w slajderze nr") + "1"}
                    style={{ zIndex: 1, boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}
                />
                <img
                    key={Date.now() + 2}
                    src={visiblePhotos[1]}
                    className="w-[263px] h-[350px] transition-opacity duration-500 ease-in-out rounded-md absolute -left-[150px] shadow-2xl photo object-contain"
                    alt={t("Pozycja zdjęcia w slajderze nr") + "2"}
                    style={{ zIndex: 2, boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}
                />
                <img
                    key={Date.now() + 3}
                    src={visiblePhotos[2]}
                    className="w-[300px] h-[400px] transition-opacity duration-500 ease-in-out rounded-md shadow-2xl photo animate-duration-300 object-contain animate-fade-left animate-ease-out"
                    alt={t("Pozycja zdjęcia w slajderze nr") + "3"}
                    style={{ zIndex: 3, boxShadow: '0px 0px 22.399999618530273px 6px rgba(0, 0, 0, 0.30)' }}
                />
                <img
                    key={Date.now() + 4}
                    src={visiblePhotos[3]}
                    className="w-[260px] h-[350px]transition-opacity duration-500 ease-in-out rounded-md absolute -right-[150px] shadow-2xl photo object-contain"
                    alt={t("Pozycja zdjęcia w slajderze nr") + "4"}
                    style={{ zIndex: 2, boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}
                />
                <img
                    key={Date.now() + 5}
                    src={visiblePhotos[4]}
                    className="w-[225px] h-[300px] transition-opacity duration-500 ease-in-out rounded-md absolute -right-[300px] shadow-2xl photo object-contain"
                    alt={t("Pozycja zdjęcia w slajderze nr") + "5"}
                    style={{ zIndex: 1, boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}
                />
                <img onClick={handleNext} className="p-10 z-10 ml-10 absolute h-32 rotate-180 md:left-[530px] left-[160px]" alt={t("Strzałka do następnego zdjęcia")} src={leftArrow} />
                <button />
            </div>
            <Link to={"/galeria"} className="w-[295px] px-[30px] py-2.5 bg-cyan-700 rounded-lg justify-center items-center gap-2.5 inline-flex mt-[50px] hover:bg-cyan-950">
                <div className="text-white text-custom-xl font-medium"> {t("Przykłady zastosowania")}</div>
            </Link>
        </section >
    );
};
