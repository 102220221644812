import { useTranslation } from "react-i18next";
import { IStatsDetail } from "./StatsContener";

interface IStatsDetailProps {
    details: IStatsDetail[];
}

export const StatsDetailRow: React.FC<IStatsDetailProps> = ({ details }) => {
    const { t } = useTranslation();

    const formatDetailInfo = (text: string) => {
        const words = text.split(' ');
        let currentLineLength = 0;
        const formattedText = words.map((word, index) => {
            if (currentLineLength + word.length > 11) {
                currentLineLength = word.length;
                return (
                    <span key={index} style={{ display: 'block' }}>
                        {word + " "}
                    </span>
                );
            } else {
                currentLineLength += word.length + 1; // Adding 1 for the space
                return (
                    <span key={index}>
                        {word + " "}
                    </span>
                );
            }
        });
        return formattedText;
    };

    return (
        <div
            className="min-w-max md:w-[800px] bg-white rounded-xl shadow md:p-2 p-1 py-3 flex flex-row justify-around"
            style={{ boxShadow: "0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)" }}
        >
            {details.map((detail, index) => {
                const translatedDetailInfo = t(detail.detailInfo);
                return (
                    <div key={index} className="flex flex-col">
                        <span className="text-center text-black text-[57px] font-semibold text-custom-extended-40px md:leading-[67.56px]">{detail.length}</span>
                        <span className="md:w-full mx-[3px] whitespace-normal break-words text-center text-black text-custom-12px font-light md:leading-[23px]">
                            {formatDetailInfo(translatedDetailInfo)}
                        </span>
                    </div>
                );
            })}
        </div>
    );
}
