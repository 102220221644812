import { useContext, useState } from "react";
import { AccessibilityContext } from "../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

interface IQuestionRow {
  title: string;
  content: string;
}

export const QuestionRow: React.FC<IQuestionRow> = ({ title, content }) => {
  const { isContrast } = useContext(AccessibilityContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <button
        className={`w-full flex justify-between items-center py-[12px] px-4 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          isContrast ? "focus:ring-yellow-400" : "focus:ring-black"
        }`}
        onClick={toggleOpen}
        aria-expanded={isOpen}
      >
        <span
          className={`text-left text-base font-medium ${
            isContrast ? "text-yellow-400" : "text-black"
          } leading-tight`}
        >
          {t(title)}
        </span>
        <svg
          className={`transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 24 24"
          stroke={isContrast ? "#E6CC15" : "#000000"}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? "auto" : 0 }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <div
          className={`px-4 py-3 text-sm ${
            isContrast ? "text-yellow-400" : "text-black"
          } leading-tight`} /* Mniejszy odstęp między wierszami */
        >
          <p>{t(content)}</p>
        </div>
      </motion.div>
      <hr className={`${isContrast ? "border-yellow-400" : "border-black"}`} />
    </div>
  );
};
