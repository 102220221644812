import { useContext } from "react";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import applestore from "../../../assets/icons/applestore.png";
import googlestore from "../../../assets/icons/googlestore.png";
import firstFeature from "../../../assets/icons/firstFeature.png";
import secondFeature from "../../../assets/icons/secondFeature.png";
import thirdFeature from "../../../assets/icons/thirdFeature.png";
import { LocationSection } from "./LocationSection";
import { useTranslation } from "react-i18next";

export const FeatureSection = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();

    return (
        <section className="diamond-template bg-[#00929D]/10 py-32 mt-[200px] flex justify-center mx-auto">
            <div className="-rotate-[4.12deg] md:pb-[30px] pb-[50px] mt-[-100px] mr-[5px] flex flex-col justify-center md:w-[900px] w-[350px]">
                <div className="md:w-[700px] self-center">
                    <div className={(isContrast ? "bg-zinc-800" : "bg-white") + " rounded-xl flex flex-row md:px-5 px-2 pt-4 justify-between pb-4"} style={{ boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}>
                        <a className="flex flex-col" href={"/lokalizacje"} >
                            <div className="flex justify-center mb-3">
                                <img className="md:w-[65px] w-[50px] pt-[10px]" src={firstFeature} alt={t("Mapa Polski ze znacznikiem TOTUPOINT")} />
                            </div>
                            <div className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-[205px] w-[100px] cursor-po text-center align-bottom text-custom-19px font-semibold md:leading-[27px] md:h-full h-[50px]"}>
                                {t("Sieć znaczników w całej Polsce")}
                            </div>
                        </a>
                        <div className="flex flex-col justify-start">
                            <div className="flex justify-center mb-1">
                                <img className="md:w-[85px] w-[70px]" src={secondFeature} alt="Logo bluetooth" />
                            </div>
                            <span className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-[205px] w-[120px] text-center text-custom-19px font-semibold md:leading-[27px]"}>
                                {t("Dedykowana bezpłatna aplikacja mobilna")}
                            </span>
                        </div>
                        <div className="flex flex-col justify-between">
                            <div className="flex justify-center mt-3 mb-2">
                                <img className="md:w-[55px] w-[50px]" src={thirdFeature} alt={t("Kompas")} />
                            </div>
                            <span className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-[205px] w-[100px] text-center text-custom-19px font-semibold md:leading-[27px]"} >
                                {t("Przejrzyste i intuicyjne instrukcje")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center text-center md:mt-20 mt-10">
                    <div>
                        <div className="mb-[10px]">
                            <span className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-28px font-light leading-[33.51px]"}>
                                {t("Pobierz bezpłatną aplikację ToTuPoint")}
                            </span>
                        </div>
                        <div className="flex flex-row gap-10 justify-center">
                            <div>
                                <div className="mt-3">
                                    <img
                                        height={150}
                                        width={150}
                                        src={googlestore}
                                        alt={t("Logo sklepu google")}
                                        className="cursor-pointer"
                                        onClick={() => window.open('https://play.google.com/store/apps/details?id=pl.pirslab.totupoint&hl=en&pli=1', '_blank')}
                                    />
                                </div>
                            </div>
                            <div className="mt-[16px]">
                                <img
                                    height={150}
                                    width={150}
                                    src={applestore}
                                    alt={t("Logo sklepu apple")}
                                    className="cursor-pointer"
                                    onClick={() => window.open('https://apps.apple.com/pl/app/totupoint/id917319251?l=pl', '_blank')}
                                />
                            </div>
                        </div>
                    </div>
                    <LocationSection />
                </div>
            </div>
        </section >
    );
}