import { useTranslation } from "react-i18next";


interface IHamburgerIconComponent {
    setIsOpen: (isOpen: boolean) => void;
}

export const HamburgerIconComponent: React.FC<IHamburgerIconComponent> = ({ setIsOpen }) => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setIsOpen(true);
        }
    };
    const { t } = useTranslation();

    return (
        <div
            className="flex md:flex-row flex-col gap-10 min-w-[34px] mt-[11px] md:mr-0 mr-1.5 md:justify-normal justify-end mb-1" >
            <button
                aria-label={t("Otwórz menu istotnych informacji")}
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onClick={() => setIsOpen(true)}
                className="flex flex-col space-y-2 mt-0.5 cursor-pointer"
            >
                <img
                    className="w-[33px] h-[4px]"
                    src="https://c.animaapp.com/KH8BuEma/img/line-2.svg"
                />
                <img
                    className="w-[33px] h-[4px]"
                    src="https://c.animaapp.com/KH8BuEma/img/line-2.svg"
                />
                <img
                    className="w-[33px] h-[4px]"
                    src="https://c.animaapp.com/KH8BuEma/img/line-2.svg"
                />
            </button>
        </div>
    );
}