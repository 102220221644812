import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'pl',
        lng: 'pl',
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
            request: (options: any, url: any, payload: any, callback: any) => {
                fetch(url, options)
                    .then(response => response.json())
                    .then(data => callback(null, { status: '200', data }))
                    .catch(error => {
                        console.error('Error loading translation:', error);
                        callback(error, { status: '404' });
                    });
            }
        },
        interpolation: {
            escapeValue: false
        }
    }, (err, t) => {
        if (err) {
            console.error('i18next initialization error:', err);
            return;
        }
        console.log("i18next is ready...");
    });

export default i18n;
