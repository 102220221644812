import { useContext, useState } from "react";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../../contexts/LocationContext";
import { Link } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from "react-datepicker";
import { pl } from "date-fns/locale";
import { ReactComponent as Pinezka } from "../../../assets/icons/ToTuPinezka.svg";
import { ReactComponent as Urzadzenie } from "../../../assets/icons/ToTuUrzadzenie.svg";
import { ReactComponent as Budynek } from "../../../assets/icons/TuTuBudynek.svg";

interface LastInstalledDevice {
    id: string;
    index: number;
    name: string;
    address: string;
    length: number;
    date: string;
}

export const LatestDevices = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();
    const { locations } = useContext(LocationContext);
    const [filterDate, setFilterDate] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const pagesToShow = 5;
    registerLocale("pl", pl);
    const parseDate = (dateString: string): Date | null => {
        const trimmedDate = dateString.trim();

        let dateObject = new Date(trimmedDate);
        if (!isNaN(dateObject.getTime())) {
            return dateObject;
        }

        const europeanDateMatch = trimmedDate.match(/^(\d{2})[-.](\d{2})[-.](\d{4})$/);
        if (europeanDateMatch) {
            const [_, day, month, year] = europeanDateMatch;
            dateObject = new Date(`${year}-${month}-${day}`);
            if (!isNaN(dateObject.getTime())) {
                return dateObject;
            }
        }

        const yearMatch = trimmedDate.match(/^\d{4}$/);
        if (yearMatch) {
            dateObject = new Date(`${trimmedDate}-01-01`);
            if (!isNaN(dateObject.getTime())) {
                return dateObject;
            }
        }

        return null;
    };

    const groupAndCountDevices = (): LastInstalledDevice[] => {
        const groupedData: Record<string, { name: string; count: number; id?: string }> = {};

        for (const key in locations) {
            const device = locations[key];
            const { name, date_of_installation, street, number, city, country, id } = device;

            if (!name || !date_of_installation) continue;

            const dateObject = parseDate(date_of_installation);
            const formattedDate = dateObject ? dateObject.toISOString().split('T')[0] : "Invalid Date";

            const streetWithNumber = street && number ? `${street} ${number}` : street || number;
            const addressParts = [streetWithNumber, city, country].filter(part => part && part.trim() !== "");
            const address = addressParts.join(", ");

            const groupKey = `${address}_${formattedDate}`;

            if (!groupedData[groupKey]) {
                groupedData[groupKey] = {
                    name,
                    count: 0,
                    id
                };
            }

            groupedData[groupKey].count += 1;
        }

        const result: LastInstalledDevice[] = Object.entries(groupedData).map(([key, { name, count, id }], index) => {
            const [address, date] = key.split('_');
            return {
                id: id ?? "",
                index: 0,
                name,
                address,
                length: count,
                date,
            };
        });

        let i = 1;

        return result
            .sort((a, b) => {
                const dateA = new Date(a.date).getTime();
                const dateB = new Date(b.date).getTime();

                if (isNaN(dateA)) return 1;
                if (isNaN(dateB)) return -1;

                return dateB - dateA;
            })
            .filter(r => r.date !== "Invalid Date")
            .map((result: LastInstalledDevice) => {
                result.index = i++;
                return result;
            });
    };

    const validateDate = (dateString: string) => {
        const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
        const europeanDatePattern = /^\d{2}-\d{2}-\d{4}$/;

        if (isoDatePattern.test(dateString)) {
            return "ISO";
        } else if (europeanDatePattern.test(dateString)) {
            return "EU";
        }
        return false;
    };

    const handleFilterDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setFilterDate(inputValue);

        const dateFormat = validateDate(inputValue);
        if (dateFormat) {
            setErrorMessage("");
        }
    };

    const handleDateValidation = () => {
        const dateFormat = validateDate(filterDate);
        if (filterDate === "") {
            setCurrentPage(1);
            setErrorMessage("");
            return;
        }

        if (!dateFormat) {
            setErrorMessage(t("Zły format daty. Użyj formatu rrrr-mm-dd lub dd-mm-rrrr."));
        } else {
            setErrorMessage("");
            const dateObject = parseDate(filterDate);
            if (dateObject) {
                const inputDateString = dateObject.toISOString().split('T')[0];
                let matchingIndex = devices.findIndex(device => {
                    const deviceDateObject = parseDate(device.date);
                    if (deviceDateObject) {
                        const deviceDateString = deviceDateObject.toISOString().split('T')[0];
                        return deviceDateString === inputDateString;
                    }
                    return false;
                });

                if (matchingIndex === -1) {
                    // If no exact match, find the first device with a date less than or equal to the input date
                    matchingIndex = devices.findIndex(device => {
                        const deviceDateObject = parseDate(device.date);
                        if (deviceDateObject) {
                            return deviceDateObject.getTime() <= dateObject.getTime();
                        }
                        return false;
                    });
                }

                if (matchingIndex !== -1) {
                    const pageNumber = Math.ceil((matchingIndex + 1) / itemsPerPage);
                    setCurrentPage(pageNumber);
                } else {
                    setErrorMessage(t("Brak instalacji dla podanej daty."));
                }
            } else {
                setErrorMessage(t("Nieprawidłowa data."));
            }
        }
    };

    const devices = groupAndCountDevices();
    const totalPages = Math.ceil(devices.length / itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    let endPage = startPage + pagesToShow - 1;

    if (endPage >= totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const paginatedDevices = devices.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <section className="flex flex-col gap-20 justify-center mb-[70px] mt-[200px]">
            <div className="md:mx-auto md:w-full">
                <div
                    className={
                        (isContrast ? "text-yellow-400" : " text-black ") +
                        " text-custom-40px font-bold leading-[47.41px] text-center md:mb-[100px] mb-[50px]"
                    }
                >
                    <h2 className="font-extrabold">
                        {t("Ostatnie instalacje")}
                    </h2>
                </div>
                <div className="bg-white rounded-2xl shadow p-4 flex flex-col gap-1 px-4 py-4 md:px-[76px] md:py-[31px]">
                    <div className="flex flex-col justify-end">
                        <div className="flex flex-col md:flex-row justify-end items-center gap-5">
                            <label htmlFor="filter-date" className="text-sm text-[#585858]">
                                {t('Przejdź do instalacji od daty') + ":"}
                            </label>
                            <div className="border-2 rounded-lg mt-0.5 h-[30px] w-[200px] md:w-[160px] flex items-center overflow-hidden">
                                <input
                                    type="text"
                                    id="filter-date"
                                    value={filterDate}
                                    onChange={handleFilterDateChange}
                                    onBlur={handleDateValidation}
                                    placeholder="rrrr-mm-dd"
                                    className="pl-5 flex-1 h-full outline-none"
                                />
                                <div className="mt-1 mr-8">
                                    <svg aria-hidden="true" className="relative right-[70px] bottom-[2px]" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3333 1.6665V4.99984M5.66667 1.6665V4.99984M1.5 8.33317H16.5M3.16667 3.33317H14.8333C15.7538 3.33317 16.5 4.07936 16.5 4.99984V16.6665C16.5 17.587 15.7538 18.3332 14.8333 18.3332H3.16667C2.24619 18.3332 1.5 17.587 1.5 16.6665V4.99984C1.5 4.07936 2.24619 3.33317 3.16667 3.33317Z" stroke="#12728C" strokeOpacity="0.65" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="flex justify-end">
                                <div className="text-red-600 text-sm mt-1 text-left" role="alert">
                                    {errorMessage}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="overflow-x-auto md:overflow-visible">
                        <div className="w-full mt-[20px]">
                            <div className="flex flex-row text-[#12728C] text-sm font-medium mb-[5px] border-b-[1px] border-[#EEEEEE] py-2">
                                <div className="px-2 text-left max-w-[40px] min-w-[40px] pt-1">Nr</div>
                                <div className="px-2 text-left max-w-[350px] min-w-[350px]">
                                    <div className="flex items-center">
                                        <Budynek width={20} height={20} fill="#12728C" />
                                        <span className="pl-2 pt-1">{t("Nazwa instytucji")}</span>
                                    </div>
                                </div>
                                <div className="px-2 text-left max-w-[150px] min-w-[150px]">
                                    <div className="flex items-center">
                                        <Pinezka width={20} height={20} fill="#12728C" />
                                        <span className="pl-2 pt-1">{t("Adres")}</span>
                                    </div>
                                </div>
                                <div className="px-2 text-left min-w-[150px]">
                                    <div className="flex items-center">
                                        <Urzadzenie width={20} height={20} fill="#12728C" stroke="none" />
                                        <span className="pl-2 pt-1">{t("Liczba instalacji")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full mt-[10px]" aria-label={t("Lista ostatnich instalacji")}>
                            {paginatedDevices.map((installation) => (
                                <a
                                    key={installation.id}
                                    href={`../lokalizacje?search=${encodeURIComponent(installation.name)}`}
                                    className="flex flex-row items-start sm:items-center text-[14px] font-medium h-auto sm:h-[50px] font-roboto border-b-[1px] border-[#EEEEEE] py-2 hover:bg-gray-100"
                                    aria-label={`${installation.index}, ${installation.name}, ${installation.address} (${installation.length})`}
                                >
                                    <div className="px-2 text-[#292d32] max-w-[40px] min-w-[40px] flex-shrink-0">
                                        {installation.index}.
                                    </div>
                                    <div className="px-2 flex-1 whitespace-nowrap overflow-hidden text-ellipsis max-w-[350px] min-w-[350px]" title={installation.name}>
                                        {installation.name}
                                    </div>
                                    <div className="px-2 font-normal text-left max-w-[150px] min-w-[150px]">
                                        <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap" title={installation.address}>
                                            {installation.address}
                                        </span>
                                    </div>
                                    <div className="px-2 font-normal text-left max-w-[150px] min-w-[150px]">
                                        <span className="pl-1">{installation.length}</span>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-center md:justify-end mt-4">
                        <nav aria-label={t("Stronicowanie")}>
                            <div className="flex md:gap-2 gap-1 flex-wrap">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="h-[28px] md:px-2.5 px-2 py-1.5 bg-neutral-100 rounded border border-[#eeeeee] text-[#404b52] text-xs font-medium cursor-pointer disabled:opacity-50"
                                    aria-label={t("Poprzednia strona") + " " + (currentPage - 1)}
                                >
                                    {t("<")}
                                </button>
                                {startPage > 1 && (
                                    <>
                                        <button
                                            onClick={() => handlePageChange(1)}
                                            className="h-[28px] md:px-2.5 px-1.5 py-1.5 bg-neutral-100 rounded border border-[#eeeeee] text-[#404b52] text-xs font-medium cursor-pointer"
                                            aria-label={t("Strona") + " 1"}
                                        >
                                            1
                                        </button>
                                        {startPage > 2 && (
                                            <span className="h-[28px] px-1 py-1.5 text-[#404b52] text-xs font-medium" aria-hidden="true">
                                                {"..."}
                                            </span>
                                        )}
                                    </>
                                )}
                                {[...Array(endPage - startPage + 1)].map((_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(startPage + index)}
                                        className={`h-[28px] md:px-2.5 px-1.5 py-1.5 rounded border ${currentPage === startPage + index
                                            ? "bg-[#12728c]/90 border-[#12728c] text-white"
                                            : "bg-neutral-100 border-[#eeeeee] text-[#404b52]"
                                            } text-xs font-medium cursor-pointer`}
                                        aria-label={t("Strona") + ` ${startPage + index}`}
                                        aria-current={currentPage === startPage + index ? "page" : undefined}
                                    >
                                        {startPage + index}
                                    </button>
                                ))}
                                {endPage < totalPages && (
                                    <>
                                        {endPage < totalPages - 1 && (
                                            <span className="h-[28px] px-1 py-1.5 text-[#404b52] text-xs font-medium" aria-hidden="true">
                                                {"..."}
                                            </span>
                                        )}
                                        <button
                                            onClick={() => handlePageChange(totalPages)}
                                            className="h-[28px] md:px-2.5 px-1.5 py-1.5 bg-neutral-100 rounded border border-[#eeeeee] text-[#404b52] text-xs font-medium cursor-pointer"
                                            aria-label={t("Strona") + ` ${totalPages}`}
                                        >
                                            {totalPages}
                                        </button>
                                    </>
                                )}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="h-[28px] md:px-2.5 px-2 py-1.5 bg-neutral-100 rounded border border-[#eeeeee] text-[#404b52] text-xs font-medium cursor-pointer disabled:opacity-50"
                                    aria-label={t("Następna strona") + " " + (currentPage + 1)}
                                >
                                    {t(">")}
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    );
};
