import { useContext, useEffect, useState } from "react";
import { FacebookPost, FacebookPosts, FacebookResponse } from "../../../models/Facebook";
import { useTranslation } from "react-i18next";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";



export const FacebookPostsSection = () => {
    const [posts, setPosts] = useState<FacebookPost[]>([]);
    const [logoUrl, setLogoUrl] = useState<string>("");
    const { t, i18n } = useTranslation();


    useEffect(() => {
        const initFacebookSDK = () => {
            window.fbAsyncInit = () => {
                FB.init({
                    appId: '1410021596322245',
                    cookie: true,
                    xfbml: true,
                    version: 'v19.0'
                });
                fetchFacebookPosts();
            };

            const js = document.createElement('script');
            js.id = 'facebook-jssdk';
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            js.onload = () => window.fbAsyncInit();
            document.body.appendChild(js);
        };

        initFacebookSDK();
    }, []);

    const fetchFacebookPosts = () => {
        window.FB.api(
            '/me',
            'get',
            {
                "fields": "picture,posts.limit(3){message,reactions.summary(total_count),full_picture{alt_text},created_time,permalink_url}",
                "access_token": "EAALWw73Yr8UBOwtAjKf5Jt6bPhUoBixMiaIrgTR7vhMmzqHSnPmjUdtLPhvVeTJLVB1t9ZAmrWWb7ZC8sHaONLZBJrCCvSRc6XY3eukICzyZB2YbfiyAVxByBudA3BFkmZCm0dqe00bTZAI9VKZCx352UKAz2aZBkYYZB2zxvp6jqZCD8LOgDlda41b2Yun4BMnQxf"
            },
            function (response: FacebookResponse) {
                if (response) {
                    setPosts(response.posts.data);
                    setLogoUrl(response.picture.data.url);
                } else {
                    console.error('Facebook API error: ', response);
                }
            }
        );
    }


    function truncateText(text: string, wordLimit: number, charLimitPerWord: number) {
        const words = text.split(' ');
        let truncatedText = words.slice(0, wordLimit).join(' ');

        if (truncatedText.length > wordLimit * charLimitPerWord) {
            truncatedText = truncatedText.slice(0, wordLimit * charLimitPerWord);
            if (text[charLimitPerWord * wordLimit] !== ' ') {
                truncatedText = truncatedText.slice(0, truncatedText.lastIndexOf(' ')) + '...';
            }
        } else if (words.length > wordLimit) {
            truncatedText += '...';
        }

        return truncatedText;
    }
    const { isContrast } = useContext(AccessibilityContext);

    return (
        <section className="md:mb-[150px] mb-[50px]">
            <h3 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-4xl font-bold leading-[47.41px] text-center mt-[40px]"}>
                {t("Bądź z Nami na bieżąco")}
            </h3>
            <h3 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-custom-28px text-center pb-[78px]"}>
                {t("Odwiedź nas na")}
                <a className=" cursor-pointer text-blue-500 hover:text-blue-700" href="https://www.facebook.com/profile.php?id=100064351974958" target="_blank">{i18n.language == "pl" ? " facebook'u" : " facebook"}  </a>
            </h3>
            <div className="flex md:flex-row flex-col gap-5">
                {posts.map((post: FacebookPost) => {
                    if (post.message == null || post.message == "") return;
                    return (
                        <div key={post.id} className=" bg-white dark:bg-gray-800 shadow rounded-lg md:w-[300px] w-full flex flex-col justify-between">
                            <div>
                                <div className="flex mb-4 px-5 pt-4">
                                    <img className="w-12 h-12 rounded-full" src={logoUrl} alt="Logo TOTUPOINT" />
                                    <div className="ml-2 mt-0.5">
                                        <span className="block font-medium text-base leading-snug text-black dark:text-gray-100">Totupoint</span>
                                        <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                                            {new Date(post.created_time).toLocaleDateString()}</span>
                                    </div>
                                </div>
                                {post.full_picture &&
                                    <img className="w-full px-2 rounded-xl h-[200px] pb-3 object-cover" alt={t("Zdjęcie pobrane z facebook'a, należące do aktualnego wpisu")} src={post.full_picture} />
                                }
                                <p className="text-gray-800 dark:text-gray-100 leading-snug md:leading-normal overflow-hidden px-4 pb-4">
                                    {truncateText(post?.message, 15, 130)}
                                    {post.message.split(' ').length > 14 && (
                                        <a target="_blank" href={post.permalink_url} aria-label={t("Zobacz post") + new Date(post.created_time).toLocaleDateString()} className="text-blue-500 hover:text-blue-700 cursor-pointer ml-1">{t("Zobacz post")}</a>
                                    )}
                                </p>
                            </div>
                            <div className="flex justify-between items-center px-5 py-4">
                                <div className="flex">
                                    <svg className="p-0.5 h-6 w-6 rounded-full z-20 bg-white dark:bg-gray-800 " xmlns='http://www.w3.org/2000/svg' xmlLang='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'><defs><linearGradient id='a1' x1='50%' x2='50%' y1='0%' y2='100%'><stop offset='0%' stopColor='#18AFFF' /><stop offset='100%' stopColor='#0062DF' /></linearGradient><filter id='c1' width='118.8%' height='118.8%' x='-9.4%' y='-9.4%' filterUnits='objectBoundingBox'><feGaussianBlur in='SourceAlpha' result='shadowBlurInner1' stdDeviation='1' /><feOffset dy='-1' in='shadowBlurInner1' result='shadowOffsetInner1' /><feComposite in='shadowOffsetInner1' in2='SourceAlpha' k2='-1' k3='1' operator='arithmetic' result='shadowInnerInner1' /><feColorMatrix in='shadowInnerInner1' values='0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0' /></filter><path id='b1' d='M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z' /></defs><g fill='none'><use fill='url(#a1)' xlinkHref='#b1' /><use fill='black' filter='url(#c1)' xlinkHref='#b1' /><path fill='white' d='M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z' /></g></svg>
                                    <span className="ml-1 text-gray-500 dark:text-gray-400 font-light">{post.reactions?.summary.total_count} {t("polubienia")}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}