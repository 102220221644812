import { useContext } from "react";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import womenIcon from "../../../assets/icons/panizlaska.png";
import signs from "../../../assets/icons/signs.png";
import { useTranslation } from "react-i18next";

export const SecuritySection = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();

    return (
        <div>
            <div className="md:mt-32 mt-16 flex flex-col md:mb-[150px]">
                <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-black text-custom-extended-40px font-bold leading-[47.41px] md:w-[700px] md:max-w-max max-w-md mx-auto text-center"}>
                    {t("Dostępność i bezpieczeństwo na każdym kroku")}
                </h2>
                <div className="relative md:mt-32 mt-[50px] gap-20 md:max-w-full max-w-md flex md:flex-row flex-col">
                    <div className="relative -top-20 -left-0 md:w-[500px] md:h-[450px] w-[100px] h-[100px] hidden md:flex">
                        <img height={400} width={400} src={womenIcon} alt={t("Kobieta z białą laską")} />
                    </div>
                    <div
                        className={(isContrast ? "bg-black" : " bg-white ") + " md:w-[640px] md:h-[280px] md:mx-0 rounded-xl flex flex-col p-7 mb-10"}
                        style={{ boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}
                    >
                        <div className="md:w-[300px] md:h-[300px] w-[100px] h-[100px] md:hidden mx-auto mb-[25px]" >
                            <img height={400} width={400} src={womenIcon} alt={t("Kobieta z białą laską")} />
                        </div>
                        <h3 className={(isContrast ? "text-yellow-400" : " text-black ") + " md:text-left mb-4 text-center text-custom-32px font-semibold md:leading-[37.93px]"}>
                            {t("Przyjazna przestrzeń")}
                        </h3>
                        <span className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-19px font-normal md:leading-7 leading-5 text-left md:max-w-max max-w-[300px]"}>
                            {t("System TOTUPOINT produkowany i wdrażany przez zespół Pirs Creative Lab ułatwia osobom z dysfunkcją wzroku przemieszczanie się w przestrzeni publicznej. Znaczniki TOTUPOINT montowane są w strategicznych punktach przestrzeni tworząc system nawigacyjno-informacyjny.")}
                        </span>
                    </div>
                </div>
                <div className="relative gap-20 md:max-w-full max-w-md flex md:flex-row flex-col">
                    <div className={(isContrast ? "bg-black" : " bg-white ") + " md:w-[500px] md:h-[280px] md:mx-0 rounded-xl flex flex-col p-7 mt-6"} style={{ boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}>
                        <div className="md:w-[300px] md:h-[300px] w-[100px] h-[100px] md:hidden mx-auto mb-[25px] pl-2">
                            <img height={90} width={90} src={signs} alt={t("Znaki")} />
                        </div>
                        <h3 className={(isContrast ? "text-yellow-400" : " text-black ") + " md:text-left mb-4 text-center text-custom-32px font-semibold md:leading-[37.93px]"}>
                            {t("Instrukcje głosowe")}
                        </h3>
                        <span className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-19px font-normal md:leading-7 leading-5 text-left md:max-w-max max-w-[300px]"}>
                            {t("Znaczniki emitując dźwięk pozwalają użytkownikom systemu precyzyjnie określić kierunek, w którym należy podążać, aby dotrzeć do celu. Lepsze zorientowanie w układzie otoczenia zapewniają dodatkowo opisy przestrzeni wyświetlane w aplikacji TOTUPOINT przy każdym miejscu, w którym zamontowany został znacznik.")}
                        </span>
                    </div>
                    <div className="absolute -right-[40px] md:w-[350px] md:h-[350px] w-[100px] h-[100px] hidden md:flex">
                        <img className="object-cover" src={signs} alt={t("Znaki")} />
                    </div>
                </div>
            </div>
        </div>
    );
}