import { Link } from "react-router-dom";
import { MapGoogle } from "../../../components/MapGoogle";
import { useContext, useEffect, useState } from "react";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../../contexts/LocationContext";

export const LocationSection = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { locations } = useContext(LocationContext);
    const [mapSize, setMapSize] = useState({ width: '900px', height: '550px' });
    const [mapKey, setMapKey] = useState(0);

    useEffect(() => {
        setMapKey(prevKey => prevKey + 1);
    }, [locations])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                var widthSizeMobile = window.innerWidth;
                setMapSize({ width: (window.innerWidth - 40) + "px", height: '400px' });
            } else {
                setMapSize({ width: '900px', height: '550px' });
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const { t } = useTranslation();

    return (
        <section className="flex flex-col justify-center items-center md:mb-[0px] mb-[20px] md:pt-[10px] pt-[0px]">
            <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold font-['Roboto'] leading-[47.41px] md:mb-[60px] mb-[40px] md:mt-[140px]"}>
                <Link to={"lokalizacje"}>{t("Lokalizacje")}</Link>
            </h2>
            <div className="md:pb-[50px] pb-[0px]" aria-label={t("Interaktywna mapa google - lokalizacja naszych znaczników")}>
                <MapGoogle key={mapKey} height={mapSize.height} width={mapSize.width} locationsFiltered={locations} />
            </div>
            <button>
                <Link to={"lokalizacje"} className={"  text-white px-[30px] py-2.5 bg-cyan-700 rounded-lg justify-center items-center gap-2.5 inline-flex mt-[20px] hover:bg-cyan-950 text-custom-xl font-medium font-['Roboto'] mb-[80px]"}>
                    {t("Więcej informacji o lokalizacjach")}
                </Link>
            </button>
        </section>
    );
}