import React, { useContext } from "react";
import { StatsContener } from "../../../components/stats/StatsContener";
import { LocationContext } from "../../../contexts/LocationContext";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";

export const StatisticsSection = () => {
    const { uniqueCity, locations } = useContext(LocationContext);
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();

    return (
        <section className="flex flex-col gap-20 justify-center mb-[70px] pt-[100px]" id="gdziejestesmy">
            <div className="md:mx-auto md:w-full ">
                <div className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold leading-[47.41px] text-center md:mb-[100px] mb-[50px]"}>
                    <h2><span className="font-extrabold">TOTUPOINT</span> {t("w liczbach")}</h2>
                </div>
                <div className="flex flex-col gap-20">
                    <div className="flex md:flex-row flex-col justify-center md:gap-20 gap-10">
                        <div className="flex flex-col text-center">
                            <span className="text-cyan-700 text-custom-8xl font-semibold">
                                {locations.length}
                            </span>
                            <span className={(isContrast ? "text-yellow-400" : "text-black") + " text-custom-30px font-medium"}>
                                {t("Lokalizacje")}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-center text-lime-600 text-custom-8xl font-semibold">
                                16
                            </span>
                            <span className={(isContrast ? "text-yellow-400" : "text-black") + " text-center text-custom-30px font-medium"}>
                                {t("Województw")}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-center text-yellow-500 text-custom-8xl font-semibold">
                                {uniqueCity}
                            </span>
                            <span className={(isContrast ? "text-yellow-400" : "text-black") + " text-center text-custom-30px font-medium"}>
                                {t("Miejscowości")}
                            </span>
                        </div>
                    </div>
                    <StatsContener />
                </div>
            </div >
        </section >
    );
}