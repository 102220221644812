import React, { ReactNode, useEffect, useState } from 'react';
import { LocationDetail } from '../models/Location';
import data from "../json/data.json";
import axios from 'axios';

interface ILocationContext {
    locations: LocationDetail[];
    uniqueCity: number;
    expandedCity: string | null;
    expandedLocation: string | null;
    setExpandedCity: (city: string | null) => void;
    setExpandedLocation: (location: string | null) => void;
}

export const LocationContext = React.createContext<ILocationContext>({
    locations: [],
    uniqueCity: 0,
    expandedCity: null,
    expandedLocation: null,
    setExpandedCity: (city: string | null) => { },
    setExpandedLocation: (location: string | null) => { }
});

interface ILocationProvider {
    children: ReactNode;
}

export const LocationProvider: React.FC<ILocationProvider> = ({ children }) => {
    const [locations, setLocations] = useState<LocationDetail[]>([]);
    const [uniqueCity, setUniqueCity] = useState<number>(0)
    const [expandedCity, setExpandedCity] = useState<string | null>(null);
    const [expandedLocation, setExpandedLocation] = useState<string | null>(null);
    useEffect(() => {
        axios.get(process.env.REACT_APP_DATABASE_API_URL + "gettagdatabase.php?locbrowser=2").then(response => {
            const locationsWithId: LocationDetail[] = Object.entries(response.data).map(([key, value]: [string, any]) => ({
                id: key,
                ...value,
            })).filter((val: LocationDetail) => val.share !== '0' && Number(val.share) !== 0);

            setLocations(locationsWithId);
            setUniqueCity(countUniqueCities(locationsWithId));
        });
    }, []);

    useEffect(() => {
    }, [])
    const countUniqueCities = (data: LocationDetail[]): number => {
        const uniqueCities = new Set<string>();

        data.forEach((item) => {
            uniqueCities.add(item.city);
        });

        return uniqueCities.size;
    }

    return (
        <LocationContext.Provider value={{ locations, uniqueCity, expandedCity, setExpandedCity, expandedLocation, setExpandedLocation }}>
            {children}
        </LocationContext.Provider>
    );
};
