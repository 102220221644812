import { useContext } from "react";
import { Link } from "react-router-dom";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import marker from "../../../assets/icons/szarymniejszy.gif"
import facebooklogo from "../../../assets/icons/facebooklogo.png"
import { useTranslation } from "react-i18next";



export const HeaderSection = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();

    return (
        <section className="flex md:flex-row flex-col pb-[100px]">
            <div className="">
                <h1 className={(isContrast ? "text-yellow-400" : "text-black") + " text-custom-14px text-[10px] italic tracking-tighter ml-[2px]"}>
                    {t("Technika dla zmysłów, świat bez barier...")}
                </h1>
                <h1 className={(isContrast ? "text-yellow-400" : "text-black") + " text-custom-40px font-extrabold md:leading-[60px] leading-[40px]"}>
                    {t("System nawigacyjno-informacyjny TOTUPOINT")}<sup style={{ fontSize: '0.75em', lineHeight: '0', paddingLeft: 5 }}>®</sup>
                </h1>
                <div className="mt-[10px] mb-8 md:w-[500px]">
                    <span className={(isContrast ? "text-yellow-400" : "text-black") + " text-custom-28px font-normal tracking-normal leading-8"}>
                        {t("Tworzymy bezpieczną przestrzeń dla osób niewidomych i słabowidzących")}
                    </span>
                </div>

                <div className="flex flex-row gap-10 mt-5 md:justify-normal justify-center">
                    <div className="bg-cyan-700 hover:bg-cyan-900 rounded-lg text-center flex items-center justify-center">
                        <a href={"#kontakt"} className="text-white text-custom-16px text-center font-medium py-1 px-5">
                            {t("Zadaj pytanie")}
                        </a>
                    </div>
                    <div className="bg-cyan-700 bg-opacity-0 rounded-lg border-2 border-cyan-700 flex items-center justify-center">
                        <a href="#onas" className="text-cyan-700 text-custom-xl font-medium py-1 px-5 hover:bg-cyan-700 hover:text-white">
                            {t("O nas")}
                        </a>
                    </div>
                </div>
                <div className="flex flex-col mt-[5px] gap-[10px]">
                    <span className={(isContrast ? "text-yellow-400" : "text-black") + " mt-[16px] text-custom-17px font-bold"}>{t("Sprawdź nas na: ")}</span>
                    <a href="https://www.facebook.com/profile.php?id=100064351974958" target="_blank">
                        <img className="w-[40px]" src={facebooklogo} alt="Facebook logo" />
                    </a>
                </div>
            </div>
            <div className="md:mt-[0px] mt-[50px]">
                <img className="md:w-[300px] w-[200px] mx-auto h-full" src={marker} alt={t("Znacznik TOTUPOINT")} />
            </div>
        </section >
    );
}